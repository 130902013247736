body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sdc-logo-container {
  margin-bottom: 25px;
}

.sdc-logo-container-small {
  margin-bottom: 25px;
  height: 3rem;
}

.qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
}

.qrcode-text {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  padding: 10px;
}

.uuid-text {
  font-size: 10px;
  color: #ccc;
  margin-top: 10px;
}

.audience-selector {
  /* // 2 columns grid */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  width: 90vh;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 90%;
  }
}

.sdc-text {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
}

.sdc-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
}

.segment-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 30vh;
  overflow: auto;
}

.selected-segment {
  background-color: #ff2ba3;
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.segment {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.horizontal-segment-pills {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.horizontal-segment-pill {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ff2ba3;
  color: #ffffff;
  font-size: 10px;
}

hr {
  width: 100%;
  margin: 20px 0;
  border: 1px dotted #cccccc41;
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #057b0f;
  color: #ffffff;
  font-size: 15px;
  border: none;
  text-align: right;
}

.button-container {
  margin-top: 20px;
}

.button:hover {
  background-color: #ff2ba3;
  color: #ffffff;
}

.local-participant {
  /* makes the div have the dimensions of a live mobile phone */
  max-width: 300px !important;
  max-height: 612px !important;
  height: 612px;
  width: 300px;
}

.side-by-side {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, auto));
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

/*1 col  */
.video-column {
  grid-area: 1 / 1 / 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/* 2 cols grid */
.chat-column {
  grid-area: 1 / 3 / 2 / 6;
  padding: 3px;
  margin-bottom: 50px;
  border-radius: 20px;
  max-height: 500px;
  
}
.participant-column {
  grid-area: 1 / 6 / 2 / 7;
  
  padding: 3px;
}
.participant-list > .title {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: left;
  margin-bottom: 15px;
}

.participant-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  border-bottom: 1px dotted #898989;
  cursor: pointer;
  gap: 10px;
  min-width: 180px;
}

.participant-avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.participant-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  
}

.connection-quality{
  font-size: 7px;
  color: #3998eb;
}