.App {
  /* // pre-wrap */
  white-space: pre-wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.user-image {
  width: 5rem;
  border-radius: 5rem;
}

.user-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  color: #ffffff;
}

.message-text {
  font-size: 1.1rem;
  margin-top: 1rem;
  color: #ccc;
  max-width: 300px;
}

.call-control {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 8px;
  margin-bottom: 1rem;
}

.call-participants {
  display: flex;
  font-size: 15px;
  padding: 8px 15px;
  color: #000;
  background-color: #ff2ba3;
  margin-bottom: 10px;
  align-self: flex-start;
  border-radius: 4px;
}

.call-stop-button {
  padding: 8px 15px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 15px;
}

.call-start-button {
  padding: 8px 15px;
  background-color: #146b00;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 15px;
}

.call-live-indicator {
  font-size: 15px;
  padding: 8px 15px;
  color: #fff;
  background-color: #b61212;
  border-radius: 25px;
}

.call-close-indicator {
  font-size: 15px;
  padding: 8px 15px;
  color: #fff;
  background-color: #5f12b6;
  border-radius: 25px;
}